* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 10px;
  background-color: #BFCDE0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

img {
  height: 100%;
  width: 65%;
  object-fit: cover;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);;
}

@media screen and (max-width: 1000px) {
  img {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  img {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {
  img {
    width: 90%;
  }
}